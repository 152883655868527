


































import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "OrderToggleBtn",
  props: {
    idx: {
      type: Number as PropType<number>,
      required: true
    },
    showInvalid: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    removed: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ["remove"],
  data() {
    return {
      showRemove: false
    };
  }
});
